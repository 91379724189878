import {
  LoadingState,
  MonkActionType,
  MonkUpdatedOneInspectionAdditionalDataAction,
  MonkUpdatedOnePricingAction,
  useMonkState,
} from '@monkvision/common';
import { useMonitoring } from '@monkvision/monitoring';
import { MonkApiConfig, useMonkApi } from '@monkvision/network';
import {
  AdditionalData,
  DamageType,
  MonkEntityType,
  PricingV2RelatedItemType,
  ProgressStatus,
  VehiclePart,
} from '@monkvision/types';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { InteriorDamage } from '../../components/InspectionReview/DamageManipulator/InteriorManipulatorModal';
import { PartDetails } from '../../components/InspectionReview/DamageManipulator/hooks';
import { ViewMode } from '../../components/InspectionReview/TabsSwitch';

export interface InspectionReviewParams {
  apiConfig: MonkApiConfig;
  inspectionId: string;
  loading: LoadingState;
}

export function useInspectionReview({ inspectionId, apiConfig, loading }: InspectionReviewParams) {
  const [viewMode, setViewMode] = useState<ViewMode>(ViewMode.EXTERIOR);
  const [checkedParts, setCheckedParts] = useState<Array<VehiclePart>>([]);
  const [selectedPart, setSelectedPart] = useState<VehiclePart | undefined>();
  const [interiorIndexSelected, setInteriorIndexSelected] = useState(-1);

  const { state, dispatch } = useMonkState();
  const { t } = useTranslation();
  const { handleError } = useMonitoring();
  const {
    getInspection,
    createPricing,
    updatePricing,
    deletePricing,
    createDamage,
    deleteDamage,
    updateAdditionalData,
  } = useMonkApi(apiConfig);

  const inspection = useMemo(
    () => state.inspections.find((i) => i.id === inspectionId),
    [state.inspections],
  );

  const mappingPartsDetails = useMemo(() => {
    const parts = state.parts.map((part) => {
      const damages = part.damages.reduce<DamageType[]>((acc, damageId) => {
        const damage = state.damages.find((value) => value.id === damageId)?.type;
        if (damage) {
          acc.push(damage);
        }
        return acc;
      }, []);
      const pricing = state.pricings.find((price) => price.relatedItemId === part.id)?.pricing;
      return { part: part.type, damages, pricing };
    });
    return parts;
  }, [state]);

  const mappingPartPrice = mappingPartsDetails.map((part) => {
    return { part: part.part, pricing: part.pricing ?? 0 };
  });

  const interiorDamageDetails: InteriorDamage[] = useMemo(() => {
    const otherDamages = inspection?.additionalData?.['other_damages'];
    return otherDamages ? (otherDamages as InteriorDamage[]) : [];
  }, [state]);

  const selectedPartDetails: PartDetails | undefined = useMemo(() => {
    if (!selectedPart && viewMode === ViewMode.INTERIOR) {
      return undefined;
    }
    const partDetails = mappingPartsDetails.find((part) => part.part === selectedPart);
    const partToModify = state.parts.find((part) => part.type === selectedPart);
    const isDamaged = !!partToModify?.damages.length;
    const damagesType = partDetails?.damages ?? [];
    const pricing =
      state.pricings.find((value) => value.relatedItemId === partToModify?.id)?.pricing ?? 0;

    return { part: selectedPart as VehiclePart, isDamaged, damagesType, pricing };
  }, [mappingPartsDetails, selectedPart]);

  const selectedInteriorDamageDetails = useMemo(() => {
    if (!interiorDamageDetails || (selectedPart && viewMode === ViewMode.EXTERIOR)) {
      return undefined;
    }
    return !interiorDamageDetails.length || interiorIndexSelected === -1
      ? undefined
      : interiorDamageDetails.at(interiorIndexSelected);
  }, [state, selectedPart, interiorIndexSelected, interiorDamageDetails]);

  const handleOnInteriorConfirm = useCallback(
    (interiorDamage: InteriorDamage) => {
      const callback = (existingData?: AdditionalData) => {
        const newAdditionalData = {
          area: interiorDamage?.area,
          damage_type: interiorDamage?.damage_type,
          repair_cost: interiorDamage?.repair_cost,
        };
        if (interiorIndexSelected !== -1 || !existingData?.['other_damages']) {
          const cpy = [...interiorDamageDetails];
          cpy[interiorIndexSelected] = newAdditionalData;
          return { ...existingData, other_damages: cpy };
        }
        return {
          ...existingData,
          other_damages: [
            ...(existingData?.['other_damages'] as unknown as AdditionalData[]),
            newAdditionalData,
          ],
        };
      };

      const action: MonkUpdatedOneInspectionAdditionalDataAction = {
        type: MonkActionType.UPDATED_ONE_INSPECTION_ADDITIONAL_DATA,
        payload: {
          inspectionId,
          additionalData: callback(inspection?.additionalData),
        },
      };
      dispatch(action);
      updateAdditionalData({ id: inspectionId, callback });
      setViewMode(ViewMode.INTERIOR);
      setInteriorIndexSelected(-1);
    },
    [interiorIndexSelected, interiorDamageDetails],
  );

  const handleOnExteriorConfirm = (editedDamage: PartDetails) => {
    const partToUpdate = state.parts.find((part) => part.type === selectedPart);
    if (!editedDamage.isDamaged) {
      const pricingToDelete = state.pricings.find(
        (pricing) => pricing.relatedItemId === partToUpdate?.id,
      );
      try {
        partToUpdate?.damages.forEach((damageId) => {
          deleteDamage({ id: inspectionId, damageId });
        });
        if (pricingToDelete) {
          deletePricing({ id: inspectionId, pricingId: pricingToDelete?.id });
        }
      } catch (e) {
        handleError(e);
        loading.onError();
      }
    }

    if (editedDamage.isDamaged) {
      const pricingToUpdate = state.pricings.find(
        (pricing) => pricing.relatedItemId === partToUpdate?.id,
      );
      if (pricingToUpdate && editedDamage.pricing !== undefined) {
        const action: MonkUpdatedOnePricingAction = {
          type: MonkActionType.UPDATED_ONE_PRICING,
          payload: {
            pricing: {
              entityType: MonkEntityType.PRICING,
              id: pricingToUpdate.id,
              inspectionId,
              relatedItemType: PricingV2RelatedItemType.PART,
              pricing: editedDamage.pricing,
            },
          },
        };
        dispatch(action);
        updatePricing({
          id: inspectionId,
          pricingId: pricingToUpdate.id,
          price: editedDamage.pricing,
        });
      }
      if (!pricingToUpdate && editedDamage.pricing) {
        createPricing({
          id: inspectionId,
          pricing: {
            pricing: editedDamage.pricing,
            type: PricingV2RelatedItemType.PART,
            vehiclePart: editedDamage.part,
          },
        })
          .then(() => {
            if (!partToUpdate) {
              getInspection({ id: inspectionId });
            }
          })
          .catch(() => {
            loading.onError(t('inspectionReview.errors.notCompleted'));
          });
      }
      const damagesFilteredByPartSelected = state.damages.filter((damage) =>
        partToUpdate?.damages.includes(damage.id),
      );
      damagesFilteredByPartSelected.forEach((damage) => {
        if (!editedDamage.damagesType.includes(damage.type)) {
          deleteDamage({ id: inspectionId, damageId: damage.id });
        }
      });

      editedDamage.damagesType.forEach((damage) => {
        if (!damagesFilteredByPartSelected.map((value) => value.type).includes(damage)) {
          createDamage({ id: inspectionId, damageType: damage, vehiclePart: editedDamage.part });
        }
      });
    }

    const newPartsValidated = selectedPart ? [...checkedParts, selectedPart] : checkedParts;
    setCheckedParts(newPartsValidated);
    setSelectedPart(undefined);
  };

  const handleExteriorCancel = () => setSelectedPart(undefined);

  const handleInteriorCancel = () => {
    setViewMode(ViewMode.INTERIOR);
    setInteriorIndexSelected(-1);
  };

  const handleAddDamage = () => {
    setViewMode(ViewMode.INTERIOR_FORM);
    setInteriorIndexSelected(interiorDamageDetails.length);
  };

  const handleDeleteIntDamage = useCallback(
    (index: number) => {
      const callback = (existingData?: AdditionalData) => {
        return {
          ...existingData,
          other_damages: interiorDamageDetails.filter((_, idx) => idx !== index),
        };
      };
      const action: MonkUpdatedOneInspectionAdditionalDataAction = {
        type: MonkActionType.UPDATED_ONE_INSPECTION_ADDITIONAL_DATA,
        payload: {
          inspectionId,
          additionalData: callback(inspection?.additionalData),
        },
      };
      dispatch(action);
      updateAdditionalData({ id: inspectionId, callback });
    },
    [interiorDamageDetails],
  );

  const handleOnUpdateIntDamage = (index: number) => {
    setInteriorIndexSelected(index);
    setViewMode(ViewMode.INTERIOR_FORM);
  };

  useEffect(() => {
    loading.start();

    const fetchInspection = async () => {
      if (!inspectionId) {
        loading.onSuccess();
        return;
      }
      const fetchedInspection = await getInspection({
        id: inspectionId,
      }).catch(() => {
        throw new Error(t('inspectionReview.errors.inspectionId'));
      });
      if (
        fetchedInspection.entities.tasks.some(
          (task) =>
            task.inspectionId === inspectionId && task.status === ProgressStatus.NOT_STARTED,
        )
      ) {
        throw new Error(t('inspectionReview.errors.notCompleted'));
      }
      fetchedInspection.entities.images.forEach((image) => {
        const imgPath = new Image();
        const imgRenderedOutput = new Image();
        imgPath.src = image.path;
        const renderedOutput = fetchedInspection.entities.renderedOutputs.find(
          (item) => item.id === image.renderedOutputs[1],
        );
        imgRenderedOutput.src = renderedOutput?.path ?? '';
      });
      loading.onSuccess();
    };

    fetchInspection()
      .then(loading.onSuccess)
      .catch((e) => {
        const error = e as Error;
        loading.onError(error.message);
      });
  }, [inspectionId]);

  return {
    selectedPart,
    interiorDamageDetails,
    selectedInteriorDamageDetails,
    viewMode,
    selectedPartDetails,
    mappingPartPrice,
    checkedParts,
    setViewMode,
    setSelectedPart,
    handleOnUpdateIntDamage,
    handleOnInteriorConfirm,
    handleOnExteriorConfirm,
    handleInteriorCancel,
    handleExteriorCancel,
    handleDeleteIntDamage,
    handleAddDamage,
  };
}

import { Styles } from '@monkvision/types';

export const styles: Styles = {
  wrapper: {
    width: '100%',
    minHeight: '100%',
    display: 'flex',
    flexDirection: 'column',
    // justifyContent: 'center',
    alignItems: 'start',
    gap: '30px',
    marginTop: '80px',
  },
  arrowsContainer: {
    position: 'absolute',
    top: '500px',
    width: '450px',
    display: 'flex',
    justifyContent: 'space-around',
  },
};

import { useLoadingState, useObjectMemo } from '@monkvision/common';
import { useCallback, useState } from 'react';
import { useMonitoring } from '@monkvision/monitoring';
import { useCreateTeslaInspectionLink } from '../useCreateTeslaInspectionLink';
import { CreateTeslaInspectionParams } from '../useCreateTeslaInspectionLink/types';

export function useInspectionLinkSingleCreate() {
  const [link, setLink] = useState<string | null>(null);
  const loading = useLoadingState();
  const { createInspectionLink } = useCreateTeslaInspectionLink();
  const { handleError } = useMonitoring();

  const generateLink = useCallback(async (params: CreateTeslaInspectionParams) => {
    loading.start();
    setLink(null);
    try {
      const inspectionLink = await createInspectionLink(params);
      setLink(inspectionLink);
      loading.onSuccess();
    } catch (err) {
      handleError(err);
      loading.onError(err);
    }
  }, []);

  const reset = () => {
    loading.onSuccess();
    setLink(null);
  };

  return useObjectMemo({ loading, link, generateLink, reset });
}

import { MonkApiConfig, useMonkApi } from '@monkvision/network';
import { useMemo } from 'react';
import { useAsyncEffect, useMonkState } from '@monkvision/common';
import { useMonitoring } from '@monkvision/monitoring';
import { TeslaAdditionalData } from '../useCreateTeslaInspectionLink/types';
import { generateDeductionDetails } from '../../utils';

export interface UseSetOriginalDeductionsParams {
  inspectionId: string;
  apiConfig: MonkApiConfig;
}

export function useSetOriginalDeductions({
  inspectionId,
  apiConfig,
}: UseSetOriginalDeductionsParams) {
  const { state } = useMonkState();
  const inspection = useMemo(
    () => state.inspections.find((i) => i.id === inspectionId),
    [state.inspections],
  );
  const { updateAdditionalData } = useMonkApi(apiConfig);
  const { handleError } = useMonitoring();

  useAsyncEffect(
    async () => {
      if (
        inspection &&
        !(inspection.additionalData as TeslaAdditionalData).tesla_original_deductions
      ) {
        const deductionDetails = generateDeductionDetails(inspection, state);
        const additionalData: TeslaAdditionalData = {
          tesla_original_deductions: deductionDetails.reduce(
            (prev, { part, pricing }) => ({ ...prev, [part]: pricing }),
            {},
          ),
        };
        await updateAdditionalData({
          id: inspectionId,
          callback: (existingData) => ({
            ...existingData,
            ...additionalData,
          }),
        });
      }
    },
    [inspection, state],
    { onReject: handleError },
  );
}

import { PropsWithChildren } from 'react';
import { Button, MantineProvider } from '@mantine/core';
import { useAuth } from '@monkvision/network';
import { IconLogout } from '@tabler/icons-react';
import { useTranslation } from 'react-i18next';
import styles from './NavbarPage.module.css';
import { mantineDarkTheme } from '../../theme';

export function NavbarPage({ children }: PropsWithChildren<unknown>) {
  const { logout } = useAuth();
  const { t } = useTranslation();

  return (
    <div className={styles['pageContainer']}>
      <MantineProvider theme={mantineDarkTheme}>
        <div className={styles['navbarContainer']}>
          <img className={styles['logo']} src='/tesla_logo.svg' alt='Tesla' />
          <Button
            onClick={logout}
            variant='subtle'
            color='red'
            rightIcon={<IconLogout size={14} />}
          >
            {t('navbar.logout')}
          </Button>
        </div>
      </MantineProvider>
      {children}
    </div>
  );
}

import { clsx } from 'clsx';
import { ActionIcon, Loader, NavLink } from '@mantine/core';
import { IconChevronRight, IconLinkPlus, IconMenu2, IconUsers, IconX } from '@tabler/icons-react';
import { useTranslation } from 'react-i18next';
import { LoadingState } from '@monkvision/common';
import styles from './DashboardDrawer.module.css';
import { TeslaInspectionStatus } from '../../hooks/useTeslaInspectionList/types';

export enum DashboardView {
  INSPECTION_LIST = 'inspection_list',
  INSPECTION_LINK_CREATE = 'inspection_link_create',
}

export interface DashboardDrawerProps {
  isOpen: boolean;
  onToggleOpen: () => void;
  view: DashboardView;
  onClickInspectionLinkSend: () => void;
  status: TeslaInspectionStatus | null;
  onClickStatus: (status: TeslaInspectionStatus | null) => void;
  counts: Record<TeslaInspectionStatus | 'all', number> | null;
  loading: LoadingState;
}

export const STATUS_TRANSLATION_KEYS: Record<TeslaInspectionStatus | 'all', string> = {
  all: 'drawer.allStatuses',
  [TeslaInspectionStatus.INSPECTION_LINK_SEND]: 'drawer.inspectionLinkCreate',
  [TeslaInspectionStatus.ONGOING_CAPTURE]: 'drawer.ongoingCapture',
  [TeslaInspectionStatus.READY_FOR_REVIEW]: 'drawer.readyForReview',
  [TeslaInspectionStatus.PDF_SENT]: 'drawer.pdfSent',
  [TeslaInspectionStatus.FINALIZED]: 'drawer.finalized',
};

const STATUS_TABS: (TeslaInspectionStatus | 'all')[] = [
  'all',
  ...Object.values(TeslaInspectionStatus),
];

export function DashboardDrawer({
  isOpen,
  onToggleOpen,
  view,
  onClickInspectionLinkSend,
  status,
  onClickStatus,
  counts,
  loading,
}: DashboardDrawerProps) {
  const { t } = useTranslation();

  return (
    <div className={clsx(styles['container'], isOpen && styles['open'])}>
      <ActionIcon
        variant={isOpen ? 'subtle' : 'outline'}
        color='dark'
        size='lg'
        className={styles['toggle']}
        onClick={onToggleOpen}
      >
        {isOpen ? <IconX size={24} /> : <IconMenu2 size={24} />}
      </ActionIcon>
      <div className={styles['content']}>
        <NavLink
          defaultOpened
          disabled={!isOpen}
          color='dark'
          label={t('drawer.customerStatus')}
          icon={<IconUsers size={16} />}
          childrenOffset={0}
        >
          {STATUS_TABS.map((tab) => (
            <NavLink
              key={tab}
              disabled={!isOpen}
              label={t(STATUS_TRANSLATION_KEYS[tab])}
              color='dark'
              description={
                <>
                  {loading.isLoading && (
                    <Loader color='black' size='xs' variant='dots' className={styles['loader']} />
                  )}
                  {loading.error && (
                    <div className={styles['countError']}>{t('drawer.countFetchError')}</div>
                  )}
                  {!loading.isLoading && !loading.error && counts?.[tab]}
                </>
              }
              icon={<IconChevronRight size={16} />}
              classNames={{ root: styles['navlinkRoot'] }}
              active={
                view === DashboardView.INSPECTION_LIST &&
                (tab === 'all' ? status === null : status === tab)
              }
              onClick={() => onClickStatus(tab === 'all' ? null : tab)}
            />
          ))}
        </NavLink>
        <div className={styles['separator']}></div>
        <NavLink
          disabled={!isOpen}
          color='blue'
          label={t('drawer.linkGeneration')}
          icon={<IconLinkPlus size={14} />}
          classNames={{ root: styles['navlinkRoot'] }}
          active={view === DashboardView.INSPECTION_LINK_CREATE}
          onClick={onClickInspectionLinkSend}
        />
      </div>
    </div>
  );
}

import { useLoadingState, useMonkTheme } from '@monkvision/common';
import { Button } from '@monkvision/common-ui-web';
import { useMonitoring } from '@monkvision/monitoring';
import { MonkLanguage } from '@monkvision/types';
import { useTranslation } from 'react-i18next';
import { useTeslaPdfGenerator } from '../../../hooks';
import { styles } from './GeneratePDFButton.styles';

export interface GeneratePDFButtonProps {
  inspectionId: string;
  lang?: MonkLanguage;
}

export function GeneratePDFButton({ inspectionId, lang }: GeneratePDFButtonProps) {
  const loading = useLoadingState();
  const { handleError } = useMonitoring();
  const { palette } = useMonkTheme();
  const { t } = useTranslation('translation', { lng: lang });
  const { generateAndDownloadPdf } = useTeslaPdfGenerator({ openInNewTab: false });

  const handleGeneratePdf = async () => {
    loading.start();
    try {
      await generateAndDownloadPdf(inspectionId);
      loading.onSuccess();
    } catch (err) {
      handleError(err);
      loading.onError(err);
    }
  };

  return (
    <Button
      onClick={handleGeneratePdf}
      loading={loading}
      variant='outline'
      primaryColor={palette.text.white}
      secondaryColor={palette.secondary.xdark}
      style={styles['pdfButton']}
    >
      {t('inspectionReview.generatePdf').toUpperCase()}
    </Button>
  );
}

import { StandardEnum } from '@monkvision/types';

export function formatNumber(value: number, lang: string): string {
  const thousandSeparator = lang.startsWith('en') ? ',' : ' ';
  return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, thousandSeparator);
}

export function formatPrice(price: number, currency: string, lang: string): string {
  const isLeftSideCurrency = ['$'].includes(currency);
  const left = isLeftSideCurrency ? currency : '';
  const right = !isLeftSideCurrency ? currency : '';
  return `${left}${formatNumber(price, lang)}${right}`;
}

export function isValidEnumValue<T extends StandardEnum<string>>(
  value: string | number | null | undefined,
  enumValues: T,
): value is T[keyof T] {
  return (Object.values(enumValues) as (string | number | null | undefined)[]).includes(value);
}

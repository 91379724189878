import { DamageType, VehiclePart } from '@monkvision/types';
import { useEffect, useState } from 'react';

export interface PartDetails {
  part: VehiclePart;
  isDamaged: boolean;
  damagesType: DamageType[];
  pricing?: number;
}

export interface DamageManipulator {
  selectedPartDetails?: PartDetails;
}

/**
 * Custom hook to handle states and callback of DamageManipulator component.
 * @param damage
 * @param onConfirm
 */
export function useDamageManipulator({ selectedPartDetails }: DamageManipulator) {
  const [newPartDetails, setNewPartDetails] = useState<PartDetails | undefined>(
    selectedPartDetails,
  );

  function toggleIsDamagedSwitch() {
    if (!newPartDetails) {
      return;
    }
    setNewPartDetails({
      part: newPartDetails.part,
      isDamaged: !newPartDetails.isDamaged,
      pricing: newPartDetails.pricing,
      damagesType: newPartDetails.damagesType,
      // TODO: Don't forget to add a modal if no damageetype has been selected
    });
  }

  function handleDamageType(selectedDamage: DamageType) {
    if (!newPartDetails) {
      return;
    }
    let newDamagesType = [...newPartDetails.damagesType];
    if (newPartDetails.damagesType.includes(selectedDamage)) {
      newDamagesType = newDamagesType.filter((damageType) => damageType !== selectedDamage);
    } else {
      newDamagesType = [...newDamagesType, selectedDamage];
    }
    const updatedPartDetails: PartDetails = {
      ...newPartDetails,
      damagesType: newDamagesType,
    };
    setNewPartDetails(updatedPartDetails);
  }

  function handleExteriorPriceChange(selectedPrice: number | undefined) {
    if (!newPartDetails) {
      return;
    }
    const updatedPartDetails: PartDetails = {
      ...newPartDetails,
      pricing: selectedPrice,
    };
    setNewPartDetails(updatedPartDetails);
  }

  useEffect(() => {
    setNewPartDetails(selectedPartDetails);
  }, [selectedPartDetails]);

  return {
    newPartDetails,
    toggleIsDamagedSwitch,
    handleDamageType,
    handleExteriorPriceChange,
  };
}

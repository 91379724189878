import ReactDOM from 'react-dom';
import { MonitoringProvider } from '@monkvision/monitoring';
import { AnalyticsProvider } from '@monkvision/analytics';
import { Auth0Provider } from '@auth0/auth0-react';
import { getEnvOrThrow } from '@monkvision/common';
import { MantineProvider } from '@mantine/core';
import { sentryMonitoringAdapter } from './sentry';
import { posthogAnalyticsAdapter } from './posthog';
import { AppRouter } from './app';
import { mantineLightTheme } from './theme';
import './index.css';
import './i18n';

ReactDOM.render(
  <MonitoringProvider adapter={sentryMonitoringAdapter}>
    <AnalyticsProvider adapter={posthogAnalyticsAdapter}>
      <MantineProvider theme={mantineLightTheme}>
        <Auth0Provider
          domain={getEnvOrThrow('REACT_APP_AUTH_DOMAIN')}
          clientId={getEnvOrThrow('REACT_APP_AUTH_CLIENT_ID')}
          authorizationParams={{
            redirect_uri: window.location.origin,
            audience: getEnvOrThrow('REACT_APP_AUTH_AUDIENCE'),
            prompt: 'login',
          }}
        >
          <AppRouter />
        </Auth0Provider>
      </MantineProvider>
    </AnalyticsProvider>
  </MonitoringProvider>,
  document.getElementById('root'),
);

import { getEnvOrThrow, useMonkAppState, zlibCompress } from '@monkvision/common';
import { useMonkApi } from '@monkvision/network';
import { VehicleType } from '@monkvision/types';
import { useCallback } from 'react';
import { TeslaInspectionStatus, TeslaModel } from '../useTeslaInspectionList/types';
import { CreateTeslaInspectionParams, TeslaAdditionalData } from './types';

function getVehicleType(model: TeslaModel): VehicleType {
  switch (model) {
    case TeslaModel.MODEL_3:
      return VehicleType.SEDAN;
    case TeslaModel.MODEL_S:
      return VehicleType.SEDAN;
    case TeslaModel.MODEL_X:
      return VehicleType.CUV;
    case TeslaModel.MODEL_Y:
      return VehicleType.CUV;
    default:
      return VehicleType.CUV;
  }
}

const getEnv = () => {
  const env = getEnvOrThrow('REACT_APP_ENVIRONMENT');
  switch (env) {
    case 'backend-staging-qa':
      return 'capture-backend-qa.tesla.staging.dev.monk.ai';
    case 'staging':
      return 'capture.tesla.staging.dev.monk.ai';
    case 'preview':
      return 'capture.tesla.preview.monk.ai';
    default:
      return 'capture.tesla.preview.monk.ai';
  }
};

export function useCreateTeslaInspectionLink() {
  const { config, authToken } = useMonkAppState();
  const { createInspection } = useMonkApi({
    authToken: authToken ?? '',
    apiDomain: config.apiDomain,
    thumbnailDomain: config.thumbnailDomain,
  });

  const createInspectionLink = useCallback(async (params: CreateTeslaInspectionParams) => {
    if (!config.allowCreateInspection) {
      throw new Error(
        'Unable to create a new inspection because config.allowCreateInspection is set to false',
      );
    }
    if (!authToken) {
      throw new Error('Unable to create a new inspection because auth token is not defined');
    }
    const additionalData: TeslaAdditionalData = {
      country: params.country,
      lease_maturity_date: params.leaseMaturityDate.toISOString(),
      status: TeslaInspectionStatus.INSPECTION_LINK_SEND,
      last_updated: new Date().toISOString(),
    };
    const { id } = await createInspection({
      ...config.createInspectionOptions,
      vehicle: {
        vin: params.vin,
        plate: params.licencePlate,
        model: params.model,
        mileageUnit: params.mileageUnit,
        mileageValue: params.odometer,
      },
      additionalData,
    });
    const token = encodeURIComponent(zlibCompress(authToken));
    return `https://${getEnv()}/?i=${id}&v=${getVehicleType(params.model)}&t=${token}`;
  }, []);

  return { createInspectionLink };
}

import { useState } from 'react';
import { clsx } from 'clsx';
import {
  DashboardDrawer,
  DashboardView,
  InspectionListView,
  NavbarPage,
  InspectionLinkCreateView,
} from '../../components';
import styles from './DashboardPage.module.css';
import {
  useTeslaInspectionCountByStatus,
  useTeslaInspectionList,
  useTeslaInspectionListFilters,
} from '../../hooks';
import { TeslaInspectionStatus } from '../../hooks/useTeslaInspectionList/types';

const CURRENCY = '$';

export function DashboardPage() {
  const [isDrawerOpen, setDrawerIsOpen] = useState(true);
  const [view, setView] = useState(DashboardView.INSPECTION_LIST);
  const [status, setStatus] = useState<TeslaInspectionStatus | null>(null);
  const { counts, loading: countsLoading } = useTeslaInspectionCountByStatus();
  const { filters, filterByDate, filterBySearchInput, filterByStatuses, toggleSortBy } =
    useTeslaInspectionListFilters();
  const {
    inspections,
    loading: listLoading,
    pagination,
    setRowsPerPage,
    goToPreviousPage,
    goToNextPage,
  } = useTeslaInspectionList(filters);

  const toggleOpenDrawer = () => setDrawerIsOpen((value) => !value);

  const selectStatus = (value: TeslaInspectionStatus | null) => {
    filterByStatuses(value ? [value] : null);
    setStatus(value);
    setView(DashboardView.INSPECTION_LIST);
  };

  return (
    <NavbarPage>
      <div className={styles['container']}>
        <DashboardDrawer
          isOpen={isDrawerOpen}
          onToggleOpen={toggleOpenDrawer}
          view={view}
          onClickInspectionLinkSend={() => setView(DashboardView.INSPECTION_LINK_CREATE)}
          status={status}
          onClickStatus={selectStatus}
          counts={counts}
          loading={countsLoading}
        />
        <div className={clsx(styles['drawerOffset'], isDrawerOpen && styles['openDrawer'])}>
          <div className={styles['pageContainer']}>
            {view === DashboardView.INSPECTION_LIST && (
              <InspectionListView
                filters={filters}
                toggleSortBy={toggleSortBy}
                inspections={inspections}
                loading={listLoading}
                showStatusColumn={status === null}
                pagination={pagination}
                setRowsPerPage={setRowsPerPage}
                goToPreviousPage={goToPreviousPage}
                goToNextPage={goToNextPage}
                currency={CURRENCY}
                filterByDate={filterByDate}
                filterBySearchInput={filterBySearchInput}
                filterByStatuses={filterByStatuses}
                status={status}
              />
            )}
            {view === DashboardView.INSPECTION_LINK_CREATE && <InspectionLinkCreateView />}
          </div>
        </div>
      </div>
    </NavbarPage>
  );
}

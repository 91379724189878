import { DamageType, MileageUnit, VehiclePart } from '@monkvision/types';
import { TeslaCountry, TeslaInspection, TeslaInspectionStatus, TeslaModel } from './types';

export const FAKE_INSPECTIONS: TeslaInspection[] = [
  {
    status: TeslaInspectionStatus.PDF_SENT,
    vin: '1GCHK29U32E227963',
    make: 'Tesla',
    model: TeslaModel.MODEL_X,
    lastUpdated: new Date('2024-10-08T10:37:57.436Z'),
    leaseMaturityDate: new Date('2024-10-12T10:37:57.436Z'),
    country: TeslaCountry.FR,
    inspectionId: '88aaf44a-977e-4b12-a743-d6afac10ffe6',
    licencePlate: 'EGV45TH',
    odometer: 24000,
    mileageUnit: MileageUnit.MILES,
    totalPricing: 1270,
    deductionDetails: [
      {
        part: VehiclePart.FENDER_FRONT_LEFT,
        pricing: 200,
        damages: [DamageType.SCRATCH, DamageType.RUSTINESS],
      },
      {
        part: VehiclePart.BUMPER_BACK,
        pricing: 150,
        damages: [DamageType.SMASH],
      },
      { part: VehiclePart.DOOR_BACK_RIGHT, pricing: 20, damages: [DamageType.SCRATCH] },
      { part: VehiclePart.ROCKER_PANEL, pricing: 550, damages: [DamageType.DENT] },
      { part: VehiclePart.WINDSHIELD_BACK, pricing: 100, damages: [DamageType.DENT] },
      { part: VehiclePart.DOOR_FRONT_RIGHT, pricing: 230, damages: [DamageType.DENT] },
      { part: VehiclePart.GRILL_LOW, pricing: 20, damages: [DamageType.DENT] },
    ],
    pdfUrl: 'https://www.google.com/',
    inspectionStart: new Date('2024-10-08T10:37:57.436Z'),
    inspectionEnd: new Date('2024-10-08T10:37:57.436Z'),
  },
  {
    status: TeslaInspectionStatus.PDF_SENT,
    vin: '1OJKE19S12L301948',
    make: 'Tesla',
    model: TeslaModel.MODEL_Y,
    lastUpdated: new Date('2024-10-08T11:37:57.436Z'),
    leaseMaturityDate: new Date('2024-10-12T11:37:57.436Z'),
    country: TeslaCountry.FR,
    inspectionId: '2c3bcb83-ad20-423d-ad74-641def836dce',
    licencePlate: 'ABD44TH',
    odometer: 50600,
    mileageUnit: MileageUnit.KM,
    totalPricing: 120,
    deductionDetails: [
      { part: VehiclePart.BUMPER_FRONT, pricing: 100, damages: [DamageType.SCRATCH] },
      { part: VehiclePart.BUMPER_BACK, pricing: 20, damages: [DamageType.SCRATCH] },
    ],
    pdfUrl: 'https://www.google.com/',
    inspectionStart: new Date('2024-10-10T10:37:57.436Z'),
    inspectionEnd: new Date('2024-10-10T10:37:57.436Z'),
  },
  {
    status: TeslaInspectionStatus.READY_FOR_REVIEW,
    vin: 'ASKC349S12L301948',
    make: 'Tesla',
    model: TeslaModel.MODEL_X,
    lastUpdated: new Date('2024-12-08T02:37:57.436Z'),
    leaseMaturityDate: new Date('2024-09-12T11:37:57.436Z'),
    country: TeslaCountry.US,
    inspectionId: 'c6e55286-ee2e-4ee8-b6fc-ea241d8ab1cf',
    mileageUnit: MileageUnit.KM,
  },
  {
    status: TeslaInspectionStatus.FINALIZED,
    vin: 'ANCWK234DWC301948',
    make: 'Tesla',
    model: TeslaModel.MODEL_Y,
    lastUpdated: new Date('2022-12-08T02:37:57.436Z'),
    leaseMaturityDate: new Date('2022-09-12T11:37:57.436Z'),
    country: TeslaCountry.US,
    inspectionId: '5cdc1546-89a4-4d3b-b915-f40a3888170c',
    mileageUnit: MileageUnit.KM,
  },
  {
    status: TeslaInspectionStatus.ONGOING_CAPTURE,
    vin: '1OJKE19S12L301948',
    make: 'Tesla',
    model: TeslaModel.MODEL_3,
    lastUpdated: new Date('2024-12-08T11:37:57.436Z'),
    leaseMaturityDate: new Date('2024-12-12T11:37:57.436Z'),
    country: TeslaCountry.FR,
    inspectionId: 'a1f41b6e-6dc4-4d72-a415-cb3d629ed174',
    licencePlate: 'AJCH458',
    odometer: 12000,
    mileageUnit: MileageUnit.MILES,
    totalPricing: 400,
    deductionDetails: [
      { part: VehiclePart.WINDSHIELD_BACK, pricing: 200, damages: [DamageType.SCRATCH] },
      { part: VehiclePart.BUMPER_FRONT, pricing: 100, damages: [DamageType.SCRATCH] },
      { part: VehiclePart.FENDER_FRONT_LEFT, pricing: 100, damages: [DamageType.SCRATCH] },
    ],
    pdfUrl: 'https://www.google.com/',
    inspectionStart: new Date('2023-10-10T10:37:57.436Z'),
    inspectionEnd: new Date('2023-10-10T10:37:57.436Z'),
  },
  {
    status: TeslaInspectionStatus.FINALIZED,
    vin: 'POF65D9S12L301948',
    make: 'Tesla',
    model: TeslaModel.MODEL_3,
    lastUpdated: new Date('2022-10-08T02:37:57.436Z'),
    leaseMaturityDate: new Date('2022-10-12T11:37:57.436Z'),
    country: TeslaCountry.US,
    inspectionId: '3aeab54f-6cb4-497a-8047-27b16fed90d5',
    mileageUnit: MileageUnit.MILES,
  },
  {
    status: TeslaInspectionStatus.PDF_SENT,
    vin: '1FTPX14V17NA61537',
    make: 'Tesla',
    model: TeslaModel.MODEL_X,
    lastUpdated: new Date('2024-10-08T10:37:57.436Z'),
    leaseMaturityDate: new Date('2024-10-12T10:37:57.436Z'),
    country: TeslaCountry.FR,
    inspectionId: 'dd3820a4-5444-408a-bf4c-6bf63a451668',
    licencePlate: 'EGV45TH',
    odometer: 24000,
    mileageUnit: MileageUnit.MILES,
    totalPricing: 1270,
    deductionDetails: [
      {
        part: VehiclePart.FENDER_FRONT_LEFT,
        pricing: 200,
        damages: [DamageType.SCRATCH, DamageType.RUSTINESS],
      },
      {
        part: VehiclePart.BUMPER_BACK,
        pricing: 150,
        damages: [DamageType.SMASH],
      },
      { part: VehiclePart.DOOR_BACK_RIGHT, pricing: 20, damages: [DamageType.SCRATCH] },
      { part: VehiclePart.ROCKER_PANEL, pricing: 550, damages: [DamageType.DENT] },
      { part: VehiclePart.WINDSHIELD_BACK, pricing: 100, damages: [DamageType.DENT] },
      { part: VehiclePart.DOOR_FRONT_RIGHT, pricing: 230, damages: [DamageType.DENT] },
      { part: VehiclePart.GRILL_LOW, pricing: 20, damages: [DamageType.DENT] },
    ],
    pdfUrl: 'https://www.google.com/',
    inspectionStart: new Date('2024-10-08T10:37:57.436Z'),
    inspectionEnd: new Date('2024-10-08T10:37:57.436Z'),
  },
  {
    status: TeslaInspectionStatus.PDF_SENT,
    vin: '1C4BJWEGXFL549064',
    make: 'Tesla',
    model: TeslaModel.MODEL_Y,
    lastUpdated: new Date('2024-10-08T11:37:57.436Z'),
    leaseMaturityDate: new Date('2024-10-12T11:37:57.436Z'),
    country: TeslaCountry.FR,
    inspectionId: 'a3a9265b-b673-4ddf-8fc6-01bb0839dbf5',
    licencePlate: 'ABD44TH',
    odometer: 50600,
    mileageUnit: MileageUnit.MILES,
    totalPricing: 120,
    deductionDetails: [
      { part: VehiclePart.BUMPER_FRONT, pricing: 100, damages: [DamageType.SCRATCH] },
      { part: VehiclePart.BUMPER_BACK, pricing: 20, damages: [DamageType.SCRATCH] },
    ],
    pdfUrl: 'https://www.google.com/',
    inspectionStart: new Date('2024-10-10T10:37:57.436Z'),
    inspectionEnd: new Date('2024-10-10T10:37:57.436Z'),
  },
  {
    status: TeslaInspectionStatus.READY_FOR_REVIEW,
    vin: 'KNAFX4A80E5130451',
    make: 'Tesla',
    model: TeslaModel.MODEL_X,
    lastUpdated: new Date('2024-12-08T02:37:57.436Z'),
    leaseMaturityDate: new Date('2024-09-12T11:37:57.436Z'),
    country: TeslaCountry.US,
    inspectionId: '2280e27d-c3a3-44e1-889e-f14b5914ef8f',
    mileageUnit: MileageUnit.MILES,
  },
  {
    status: TeslaInspectionStatus.FINALIZED,
    vin: '1J4GZ78S6SC544341',
    make: 'Tesla',
    model: TeslaModel.MODEL_Y,
    lastUpdated: new Date('2022-12-08T02:37:57.436Z'),
    leaseMaturityDate: new Date('2022-09-12T11:37:57.436Z'),
    country: TeslaCountry.US,
    inspectionId: '5c960c0a-3ccc-4d64-a6d4-ca92b5b51447',
    mileageUnit: MileageUnit.MILES,
  },
  {
    status: TeslaInspectionStatus.ONGOING_CAPTURE,
    vin: 'JTEGD21A070135524',
    make: 'Tesla',
    model: TeslaModel.MODEL_3,
    lastUpdated: new Date('2024-12-08T11:37:57.436Z'),
    leaseMaturityDate: new Date('2024-12-12T11:37:57.436Z'),
    country: TeslaCountry.FR,
    inspectionId: 'bba2595d-2383-43c3-a637-7a3f664b1ecf',
    licencePlate: 'AJCH458',
    odometer: 12000,
    mileageUnit: MileageUnit.KM,
    totalPricing: 400,
    deductionDetails: [
      { part: VehiclePart.WINDSHIELD_BACK, pricing: 200, damages: [DamageType.SCRATCH] },
      { part: VehiclePart.BUMPER_FRONT, pricing: 100, damages: [DamageType.SCRATCH] },
      { part: VehiclePart.FENDER_FRONT_LEFT, pricing: 100, damages: [DamageType.SCRATCH] },
    ],
    pdfUrl: 'https://www.google.com/',
    inspectionStart: new Date('2023-10-10T10:37:57.436Z'),
    inspectionEnd: new Date('2023-10-10T10:37:57.436Z'),
  },
  {
    status: TeslaInspectionStatus.FINALIZED,
    vin: '1C4NJCBAXFD271160',
    make: 'Tesla',
    model: TeslaModel.MODEL_3,
    lastUpdated: new Date('2022-10-08T02:37:57.436Z'),
    leaseMaturityDate: new Date('2022-10-12T11:37:57.436Z'),
    country: TeslaCountry.US,
    inspectionId: 'b4ad423c-2565-401e-9c31-89e8b1cc500f',
    mileageUnit: MileageUnit.KM,
  },
  {
    status: TeslaInspectionStatus.PDF_SENT,
    vin: 'JNKCV51F74M748739',
    make: 'Tesla',
    model: TeslaModel.MODEL_X,
    lastUpdated: new Date('2024-10-08T10:37:57.436Z'),
    leaseMaturityDate: new Date('2024-10-12T10:37:57.436Z'),
    country: TeslaCountry.FR,
    inspectionId: 'f7acb65f-ec95-494d-8202-aec983be569a',
    licencePlate: 'EGV45TH',
    odometer: 24000,
    mileageUnit: MileageUnit.KM,
    totalPricing: 1270,
    deductionDetails: [
      {
        part: VehiclePart.FENDER_FRONT_LEFT,
        pricing: 200,
        damages: [DamageType.SCRATCH, DamageType.RUSTINESS],
      },
      {
        part: VehiclePart.BUMPER_BACK,
        pricing: 150,
        damages: [DamageType.SMASH],
      },
      { part: VehiclePart.DOOR_BACK_RIGHT, pricing: 20, damages: [DamageType.SCRATCH] },
      { part: VehiclePart.ROCKER_PANEL, pricing: 550, damages: [DamageType.DENT] },
      { part: VehiclePart.WINDSHIELD_BACK, pricing: 100, damages: [DamageType.DENT] },
      { part: VehiclePart.DOOR_FRONT_RIGHT, pricing: 230, damages: [DamageType.DENT] },
      { part: VehiclePart.GRILL_LOW, pricing: 20, damages: [DamageType.DENT] },
    ],
    pdfUrl: 'https://www.google.com/',
    inspectionStart: new Date('2024-10-08T10:37:57.436Z'),
    inspectionEnd: new Date('2024-10-08T10:37:57.436Z'),
  },
  {
    status: TeslaInspectionStatus.PDF_SENT,
    vin: '1HGEM21941L085641',
    make: 'Tesla',
    model: TeslaModel.MODEL_Y,
    lastUpdated: new Date('2024-10-08T11:37:57.436Z'),
    leaseMaturityDate: new Date('2024-10-12T11:37:57.436Z'),
    country: TeslaCountry.FR,
    inspectionId: 'f1c04adf-c5cd-4563-836e-8bbdf3d41923',
    licencePlate: 'ABD44TH',
    odometer: 50600,
    mileageUnit: MileageUnit.MILES,
    totalPricing: 120,
    deductionDetails: [
      { part: VehiclePart.BUMPER_FRONT, pricing: 100, damages: [DamageType.SCRATCH] },
      { part: VehiclePart.BUMPER_BACK, pricing: 20, damages: [DamageType.SCRATCH] },
    ],
    pdfUrl: 'https://www.google.com/',
    inspectionStart: new Date('2024-10-10T10:37:57.436Z'),
    inspectionEnd: new Date('2024-10-10T10:37:57.436Z'),
  },
  {
    status: TeslaInspectionStatus.READY_FOR_REVIEW,
    vin: '1N4AA51E19C806598',
    make: 'Tesla',
    model: TeslaModel.MODEL_X,
    lastUpdated: new Date('2024-12-08T02:37:57.436Z'),
    leaseMaturityDate: new Date('2024-09-12T11:37:57.436Z'),
    country: TeslaCountry.US,
    inspectionId: 'ba3c6b2c-dc3f-4742-9483-7e0198e2cbe4',
    mileageUnit: MileageUnit.MILES,
  },
  {
    status: TeslaInspectionStatus.FINALIZED,
    vin: '4A32B2FFXCE045056',
    make: 'Tesla',
    model: TeslaModel.MODEL_Y,
    lastUpdated: new Date('2022-12-08T02:37:57.436Z'),
    leaseMaturityDate: new Date('2022-09-12T11:37:57.436Z'),
    country: TeslaCountry.US,
    inspectionId: 'eaad0ec8-d9d1-48b1-a5a6-0f802de317cf',
    mileageUnit: MileageUnit.MILES,
  },
  {
    status: TeslaInspectionStatus.ONGOING_CAPTURE,
    vin: '1J4PR4GK9AC155594',
    make: 'Tesla',
    model: TeslaModel.MODEL_3,
    lastUpdated: new Date('2024-12-08T11:37:57.436Z'),
    leaseMaturityDate: new Date('2024-12-12T11:37:57.436Z'),
    country: TeslaCountry.FR,
    inspectionId: '807ca81a-cc7a-4434-a09a-bcd9bcc1bf22',
    licencePlate: 'AJCH458',
    odometer: 12000,
    mileageUnit: MileageUnit.MILES,
    totalPricing: 400,
    deductionDetails: [
      { part: VehiclePart.WINDSHIELD_BACK, pricing: 200, damages: [DamageType.SCRATCH] },
      { part: VehiclePart.BUMPER_FRONT, pricing: 100, damages: [DamageType.SCRATCH] },
      { part: VehiclePart.FENDER_FRONT_LEFT, pricing: 100, damages: [DamageType.SCRATCH] },
    ],
    pdfUrl: 'https://www.google.com/',
    inspectionStart: new Date('2023-10-10T10:37:57.436Z'),
    inspectionEnd: new Date('2023-10-10T10:37:57.436Z'),
  },
  {
    status: TeslaInspectionStatus.FINALIZED,
    vin: '2C3CA5CV3AH214450',
    make: 'Tesla',
    model: TeslaModel.MODEL_3,
    lastUpdated: new Date('2022-10-08T02:37:57.436Z'),
    leaseMaturityDate: new Date('2022-10-12T11:37:57.436Z'),
    country: TeslaCountry.US,
    inspectionId: '5900b2ef-a75a-4f05-a42d-0758399fcd89',
    mileageUnit: MileageUnit.MILES,
  },
  {
    status: TeslaInspectionStatus.PDF_SENT,
    vin: '1B3EL36X03N542419',
    make: 'Tesla',
    model: TeslaModel.MODEL_X,
    lastUpdated: new Date('2024-10-08T10:37:57.436Z'),
    leaseMaturityDate: new Date('2024-10-12T10:37:57.436Z'),
    country: TeslaCountry.FR,
    inspectionId: '6ee77387-fa1b-491d-a62e-09a1bdd51a17',
    licencePlate: 'EGV45TH',
    odometer: 24000,
    mileageUnit: MileageUnit.MILES,
    totalPricing: 1270,
    deductionDetails: [
      {
        part: VehiclePart.FENDER_FRONT_LEFT,
        pricing: 200,
        damages: [DamageType.SCRATCH, DamageType.RUSTINESS],
      },
      {
        part: VehiclePart.BUMPER_BACK,
        pricing: 150,
        damages: [DamageType.SMASH],
      },
      { part: VehiclePart.DOOR_BACK_RIGHT, pricing: 20, damages: [DamageType.SCRATCH] },
      { part: VehiclePart.ROCKER_PANEL, pricing: 550, damages: [DamageType.DENT] },
      { part: VehiclePart.WINDSHIELD_BACK, pricing: 100, damages: [DamageType.DENT] },
      { part: VehiclePart.DOOR_FRONT_RIGHT, pricing: 230, damages: [DamageType.DENT] },
      { part: VehiclePart.GRILL_LOW, pricing: 20, damages: [DamageType.DENT] },
    ],
    pdfUrl: 'https://www.google.com/',
    inspectionStart: new Date('2024-10-08T10:37:57.436Z'),
    inspectionEnd: new Date('2024-10-08T10:37:57.436Z'),
  },
  {
    status: TeslaInspectionStatus.PDF_SENT,
    vin: '5N1AR18W45C720833',
    make: 'Tesla',
    model: TeslaModel.MODEL_Y,
    lastUpdated: new Date('2024-10-08T11:37:57.436Z'),
    leaseMaturityDate: new Date('2024-10-12T11:37:57.436Z'),
    country: TeslaCountry.FR,
    inspectionId: '6ada92df-441c-4388-8e9c-616e5e57c06d',
    licencePlate: 'ABD44TH',
    odometer: 50600,
    mileageUnit: MileageUnit.MILES,
    totalPricing: 120,
    deductionDetails: [
      { part: VehiclePart.BUMPER_FRONT, pricing: 100, damages: [DamageType.SCRATCH] },
      { part: VehiclePart.BUMPER_BACK, pricing: 20, damages: [DamageType.SCRATCH] },
    ],
    pdfUrl: 'https://www.google.com/',
    inspectionStart: new Date('2024-10-10T10:37:57.436Z'),
    inspectionEnd: new Date('2024-10-10T10:37:57.436Z'),
  },
  {
    status: TeslaInspectionStatus.READY_FOR_REVIEW,
    vin: '1HGCF86661A199784',
    make: 'Tesla',
    model: TeslaModel.MODEL_X,
    lastUpdated: new Date('2024-12-08T02:37:57.436Z'),
    leaseMaturityDate: new Date('2024-09-12T11:37:57.436Z'),
    country: TeslaCountry.US,
    inspectionId: '16eaa7ed-e436-49d4-999f-abaf35815f51',
    mileageUnit: MileageUnit.MILES,
  },
  {
    status: TeslaInspectionStatus.FINALIZED,
    vin: 'JTHBK262X75015902',
    make: 'Tesla',
    model: TeslaModel.MODEL_Y,
    lastUpdated: new Date('2022-12-08T02:37:57.436Z'),
    leaseMaturityDate: new Date('2022-09-12T11:37:57.436Z'),
    country: TeslaCountry.US,
    inspectionId: 'cfa8e755-80ba-49c1-9a57-38c05242d656',
    mileageUnit: MileageUnit.MILES,
  },
  {
    status: TeslaInspectionStatus.ONGOING_CAPTURE,
    vin: '1C3CDZAG3EN278455',
    make: 'Tesla',
    model: TeslaModel.MODEL_3,
    lastUpdated: new Date('2024-12-08T11:37:57.436Z'),
    leaseMaturityDate: new Date('2024-12-12T11:37:57.436Z'),
    country: TeslaCountry.FR,
    inspectionId: '485afb48-7899-41da-90da-bd95376ec1a3',
    licencePlate: 'AJCH458',
    odometer: 12000,
    mileageUnit: MileageUnit.MILES,
    totalPricing: 400,
    deductionDetails: [
      { part: VehiclePart.WINDSHIELD_BACK, pricing: 200, damages: [DamageType.SCRATCH] },
      { part: VehiclePart.BUMPER_FRONT, pricing: 100, damages: [DamageType.SCRATCH] },
      { part: VehiclePart.FENDER_FRONT_LEFT, pricing: 100, damages: [DamageType.SCRATCH] },
    ],
    pdfUrl: 'https://www.google.com/',
    inspectionStart: new Date('2023-10-10T10:37:57.436Z'),
    inspectionEnd: new Date('2023-10-10T10:37:57.436Z'),
  },
  {
    status: TeslaInspectionStatus.FINALIZED,
    vin: '1C4BJWEG9FL522986',
    make: 'Tesla',
    model: TeslaModel.MODEL_3,
    lastUpdated: new Date('2022-10-08T02:37:57.436Z'),
    leaseMaturityDate: new Date('2022-10-12T11:37:57.436Z'),
    country: TeslaCountry.US,
    inspectionId: '80cd6c35-66a8-4e33-80a9-07adf3cb2751',
    mileageUnit: MileageUnit.MILES,
  },
];

export const FAKE_TOTAL_ELEMENTS = 254;

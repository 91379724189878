import { Image, RenderedOutput } from '@monkvision/types';
import { useMonkState, useMonkTheme } from '@monkvision/common';
import { CSSProperties, useEffect, useMemo, useState } from 'react';
import { styles } from './ImageDetailedView.styles';

const MIN_SCALE = 1;
const MAX_SCALE = 4;
const ZOOM_TICK = 0.2;

/**
 * Props accepted by the ImageDetailedView component.
 */
export type ImageDetailedViewProps = {
  /**
   * The image to display the details of.
   */
  image: Image;
  /**
   * The language to be used by the component.
   *
   * @default en
   */
  showDamage: boolean;
  lang?: string | null;
  /**
   * Boolean indicating if the gallery button (used to go back to the gallery if this component is used inside the
   * gallery) must be displayed or not.
   *
   * @default true
   */
  showGalleryButton?: boolean;
  /**
   * Callback called when the user presses the close button.
   */
  onClose?: () => void;
  /**
   * Callback called when the user presses the gallery button if it is displayed.
   */
  onPrev?: () => void;
  onNext?: () => void;
  onNavigateToGallery?: () => void;

  onShowDamage?: () => void;

  reportMode?: boolean;
} & (
  | {
      /**
       * Boolean indicating if this component is displayed in "capture" mode. Capture mode enables features such as
       * compliance, retakes, navigating to capture etc. Set this prop to `true` if your user is currently capturing
       * pictures for their inspection.
       *
       * @default false
       */
      captureMode: true;
      /**
       * Boolean indicating if the capture button must be displayed or not. This prop can only be specified if
       * `captureMode` is set to true.
       *
       * @default true
       */
      showCaptureButton?: boolean;
      /**
       * Callback called when the user presses the capture button. This prop can only be specified if `captureMode` is
       * set to true.
       */
      onNavigateToCapture?: () => void;
      /**
       * Callback called when the user presses the retake button. This prop can only be specified if `captureMode` is
       * set to true.
       */
      onRetake?: () => void;
    }
  | {
      /**
       * Boolean indicating if this component is displayed in "capture" mode. Capture mode enables features such as
       * compliance, retakes, navigating to capture etc. Set this prop to `true` if your user is currently capturing
       * pictures for their inspection.
       *
       * @default false
       */
      captureMode: false;
    }
);

function getBackgroundImage(
  image: Image,
  renderedOutputs: RenderedOutput[],
  reportMode: boolean | undefined,
  showDamage: boolean,
): string {
  if (!reportMode) {
    return `url(${image.path})`;
  }

  const renderedOutput = renderedOutputs.find(
    (item) =>
      item.additionalData?.['description'] === 'rendering of detected damages' &&
      item.id === image.renderedOutputs[0],
  );
  return showDamage ? renderedOutput?.path || image.path : image.path;
}

export function useImageDetailedView(props: ImageDetailedViewProps) {
  const [isCmdPressed, setIsCmdPressed] = useState(false);
  const [scale, setScale] = useState(1);
  const [origin, setOrigin] = useState({ x: '50%', y: '50%' });
  const [isMouseOver, setIsMouseOver] = useState(true);

  const { state } = useMonkState();

  const handleKeyDown = (event: KeyboardEvent) => {
    if (event.metaKey) {
      setIsCmdPressed(true);
    }
  };

  const handleKeyUp = (event: KeyboardEvent) => {
    if (!event.metaKey) {
      setIsCmdPressed(false);
    }
  };

  const handleWheel = (event: WheelEvent) => {
    event.preventDefault();

    const imgElement = event.target as HTMLImageElement;
    const { offsetX, offsetY, deltaY } = event;

    if (isCmdPressed) {
      const imageRect = imgElement.getBoundingClientRect();

      const zoomIn = deltaY < 0;
      const newScale = zoomIn ? scale + ZOOM_TICK : scale - ZOOM_TICK;
      const clampedScale = Math.min(Math.max(MIN_SCALE, newScale), MAX_SCALE);

      const mouseXPercent = (offsetX / imageRect.width) * 100;
      const mouseYPercent = (offsetY / imageRect.height) * 100;
      setScale(clampedScale);
      setOrigin({
        x: `${mouseXPercent}%`,
        y: `${mouseYPercent}%`,
      });
    }
  };

  const backgroundImage = useMemo(
    () =>
      getBackgroundImage(props.image, state.renderedOutputs, props.reportMode, props.showDamage),
    [props.image, state.renderedOutputs, props.showDamage],
  );

  const handleMouseMove = (event: MouseEvent) => {
    const target = event.target as Element;
    const elementUnderMouse = target.closest('.parent');
    if (elementUnderMouse && elementUnderMouse.className === 'parent') {
      setIsMouseOver(true);
    } else {
      setIsMouseOver(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousemove', handleMouseMove);

    return () => {
      document.removeEventListener('mousemove', handleMouseMove);
    };
  }, [isMouseOver]);

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown);
    document.addEventListener('keyup', handleKeyUp);
    document.addEventListener('wheel', handleWheel, { passive: false });

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
      document.removeEventListener('keyup', handleKeyUp);
      document.removeEventListener('wheel', handleWheel);
    };
  }, [isCmdPressed, scale]);

  useEffect(() => setScale(1), [backgroundImage]);

  return { backgroundImage, scale, origin, isMouseOver };
}

export function useImageDetailedViewStyles(props: ImageDetailedViewProps, isMouseOver: boolean) {
  const { palette } = useMonkTheme();

  let rightContainerJustifyContent = 'start';
  if (props.captureMode) {
    rightContainerJustifyContent = props.showGalleryButton ? 'space-between' : 'end';
  }

  return {
    mainContainerStyle: {
      ...styles['mainContainer'],
    },
    overlayStyle: {
      ...styles['overlay'],
    },
    imageContainerStyle: {
      ...styles['imageContainer'],
    },
    topContainerStyle: {
      ...styles['topContainer'],
    },
    leftContainerStyle: {
      ...styles['leftContainer'],
      opacity: isMouseOver ? '1' : '0',
    },
    overlayContainerStyle: {
      ...styles['overlayContainer'],
      opacity: isMouseOver ? '1' : '0',
    },
    rightContainerStyle: {
      ...styles['rightContainer'],
      justifyContent: rightContainerJustifyContent,
      opacity: isMouseOver ? '1' : '0',
    },
    closeButton: {
      primaryColor: palette.secondary.xdark,
      secondaryColor: palette.text.white,
    },
    prevButton: {
      ...styles['prevButton'],
    },
    nextButton: {
      ...styles['nextButton'],
    },
    galleryButton: {
      primaryColor: palette.secondary.xdark,
      secondaryColor: palette.text.white,
      style: {
        visibility: props.showGalleryButton ?? true ? 'visible' : 'hidden',
      } as CSSProperties,
    },
    cameraButton: {
      style: {
        visibility: props.captureMode && (props.showCaptureButton ?? true) ? 'visible' : 'hidden',
      } as CSSProperties,
    },
  };
}

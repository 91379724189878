import { useState } from 'react';
import { useObjectMemo } from '@monkvision/common';
import { DateFilter, SortByProperty, SortDirection, TeslaInspectionListFilters } from './types';
import { TeslaInspectionStatus } from '../useTeslaInspectionList/types';

export interface UseTeslaInspectionListFiltersResult {
  filters: TeslaInspectionListFilters;
  toggleSortBy: (property: SortByProperty) => void;
  filterByStatuses: (value: TeslaInspectionStatus[] | null) => void;
  filterBySearchInput: (value: string | null) => void;
  filterByDate: (
    filterByLeaseMaturityDate: boolean,
    filterByLastUpdated: boolean,
    min: Date | null,
    max: Date | null,
  ) => void;
}

function opposite(dir: SortDirection): SortDirection {
  return dir === SortDirection.ASCENDING ? SortDirection.DESCENDING : SortDirection.ASCENDING;
}

export function useTeslaInspectionListFilters(): UseTeslaInspectionListFiltersResult {
  const [sortDirection, setSortDirection] = useState(SortDirection.DESCENDING);
  const [sortBy, setSortBy] = useState(SortByProperty.LEASE_MATURITY_DATE);
  const [statuses, setStatuses] = useState<TeslaInspectionStatus[] | null>(null);
  const [leaseMaturityDate, setLeaseMaturityDate] = useState<DateFilter | null>(null);
  const [lastUpdated, setLastUpdated] = useState<DateFilter | null>(null);
  const [searchInput, setSearchInput] = useState<string | null>(null);

  const filters = useObjectMemo({
    sortDirection,
    sortBy,
    statuses,
    leaseMaturityDate,
    lastUpdated,
    searchInput,
  });

  const toggleSortBy = (property: SortByProperty) => {
    if (sortBy === property) {
      setSortDirection((value) => opposite(value));
    } else {
      setSortBy(property);
      setSortDirection(SortDirection.DESCENDING);
    }
  };

  const filterByStatuses = (value: TeslaInspectionStatus[] | null) => {
    setStatuses(value);
  };

  const filterBySearchInput = (value: string | null) => {
    setSearchInput(value);
  };

  const filterByDate = (
    filterByLeaseMaturityDate: boolean,
    filterByLastUpdated: boolean,
    min: Date | null,
    max: Date | null,
  ) => {
    if (!min || !max) {
      setLeaseMaturityDate(null);
      setLastUpdated(null);
    } else {
      setLeaseMaturityDate(filterByLeaseMaturityDate ? { min, max } : null);
      setLastUpdated(filterByLastUpdated ? { min, max } : null);
    }
  };

  return {
    filters,
    toggleSortBy,
    filterByStatuses,
    filterBySearchInput,
    filterByDate,
  };
}

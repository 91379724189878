import { CurrencyCode } from '@monkvision/types';
import { PartDetails, useDamageManipulator } from './hooks';
import { ExteriorManipulatorModal } from './ExteriorManipulatorModal';

export interface DamageManipulatorProps {
  selectedPartDetails: PartDetails | undefined;
  isVisible: boolean;
  currency?: CurrencyCode;
  handleExteriorConfirm?: (partDetails: PartDetails) => void;
  onCancel?: () => void;
}

export function DamageManipulator({
  selectedPartDetails,
  currency = CurrencyCode.USD,
  isVisible,
  handleExteriorConfirm,
  onCancel,
}: DamageManipulatorProps) {
  const { newPartDetails, toggleIsDamagedSwitch, handleDamageType, handleExteriorPriceChange } =
    useDamageManipulator({
      selectedPartDetails,
    });

  if (!isVisible || !newPartDetails?.part) {
    return null;
  }

  return (
    <ExteriorManipulatorModal
      selectedPartDetails={newPartDetails}
      currency={currency}
      onDamageToggle={toggleIsDamagedSwitch}
      onDamageType={handleDamageType}
      onPriceChange={handleExteriorPriceChange}
      onConfirm={handleExteriorConfirm}
      onCancel={onCancel}
    />
  );
}

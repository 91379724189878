import { Document, Font } from '@react-pdf/renderer';
import { styles } from './TeslaPdf.styles';
import { TopBar } from './TopBar';
import { VehicleDetails } from './VehicleDetails';
import { VehiclePhotos } from './VehiclePhotos';
import { PdfInspectionData } from '../../hooks/useTeslaPdfGenerator/types';
import { DamageReport } from './DamageReport';
import { Summary } from './Summary';
import { TeslaPdfPage } from './TeslaPdfPage';
import { Footer } from './Footer';

Font.register({
  family: 'Roboto',
  fonts: [
    { src: '/fonts/Roboto/Roboto-Thin.ttf', fontStyle: 'normal', fontWeight: 'thin' },
    { src: '/fonts/Roboto/Roboto-Light.ttf', fontStyle: 'normal', fontWeight: 'light' },
    { src: '/fonts/Roboto/Roboto-Regular.ttf', fontStyle: 'normal', fontWeight: 'normal' },
    { src: '/fonts/Roboto/Roboto-Medium.ttf', fontStyle: 'normal', fontWeight: 'medium' },
    { src: '/fonts/Roboto/Roboto-Bold.ttf', fontStyle: 'normal', fontWeight: 'bold' },
    { src: '/fonts/Roboto/Roboto-Black.ttf', fontStyle: 'normal', fontWeight: 'heavy' },
  ],
});

export interface TeslaPdfProps {
  data: PdfInspectionData;
  currency: string;
  lang: string;
}

export function TeslaPdf({ data, currency, lang }: TeslaPdfProps) {
  return (
    <Document
      title={`Tesla Condition Report ${data.inspectionId}`}
      author='Tesla'
      language='en'
      pageMode='useNone'
      pageLayout='singlePage'
      style={styles.document}
    >
      <TeslaPdfPage header={<TopBar lang={lang} />}>
        <VehicleDetails vehicle={data.vehicle} currency={currency} lang={lang} />
        <VehiclePhotos photos={data.photos} lang={lang} />
        <DamageReport damagedSides={data.damagedSides} currency={currency} lang={lang} />
      </TeslaPdfPage>
      <TeslaPdfPage footer={<Footer />}>
        <Summary
          damagedSides={data.damagedSides}
          interiorDamages={data.interiorDamages}
          currency={currency}
          lang={lang}
        />
      </TeslaPdfPage>
    </Document>
  );
}

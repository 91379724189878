import { useLoadingState, useObjectTranslation } from '@monkvision/common';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { unparse } from 'papaparse';
import { TeslaDeductionDetails, TeslaInspection } from '../useTeslaInspectionList/types';
import { formatPrice } from '../../utils';
import { TeslaDeductionDetailsRecord } from '../useCreateTeslaInspectionLink/types';

export interface UseExportToCsvParams {
  inspections: TeslaInspection[];
  currency: string;
}

function getDeductionDetailsRecord(
  deductionDetails?: TeslaDeductionDetails[],
): TeslaDeductionDetailsRecord | undefined {
  return deductionDetails?.reduce(
    (prev, { part, pricing, damages }) => ({ ...prev, [part]: { pricing, damages } }),
    {},
  );
}

export function useExportToCsv({ inspections, currency }: UseExportToCsvParams) {
  const { i18n } = useTranslation();
  const { tObj } = useObjectTranslation();
  const loading = useLoadingState();

  const createCsv = useCallback(() => {
    const csvArray: string[][] = [];
    csvArray.push([
      'VIN',
      'Country',
      'Licence Plate',
      'Lease Maturity Date',
      'Model',
      'Odometer',
      'Mileage Unit',
      'Status',
      'ID',
      'Total Deductions',
      'Deduction Details',
      'Original Deduction Details',
      'Last Updated',
      'Inspection Start',
      'Inspection End',
    ]);
    inspections.forEach((inspection) => {
      const deductionDetails = inspection.deductionDetails
        ? JSON.stringify(getDeductionDetailsRecord(inspection.deductionDetails))
        : '';
      const originalDeductionDetails = inspection.originalDeductionDetails
        ? JSON.stringify(getDeductionDetailsRecord(inspection.originalDeductionDetails))
        : '';
      csvArray.push([
        inspection.vin ?? '',
        inspection.country ?? '',
        inspection.licencePlate ?? '',
        inspection.leaseMaturityDate
          ? dayjs(inspection.leaseMaturityDate).format('YYYY-MM-DD')
          : '',
        inspection.model ?? '',
        inspection.odometer?.toString() ?? '',
        inspection.mileageUnit ?? '',
        inspection.status ?? '',
        inspection.inspectionId ?? '',
        inspection.totalPricing
          ? formatPrice(inspection.totalPricing, currency, i18n.language)
          : '',
        deductionDetails,
        originalDeductionDetails ?? deductionDetails,
        inspection.lastUpdated
          ? dayjs(inspection.leaseMaturityDate).format('YYYY-MM-DD HH:mm:ss')
          : '',
        inspection.inspectionStart
          ? dayjs(inspection.leaseMaturityDate).format('YYYY-MM-DD HH:mm:ss')
          : '',
        inspection.inspectionEnd
          ? dayjs(inspection.leaseMaturityDate).format('YYYY-MM-DD HH:mm:ss')
          : '',
      ]);
    });
    return unparse(csvArray);
  }, [inspections, currency, tObj, i18n.language]);

  const exportToCsv = useCallback(() => {
    loading.start();
    const csv = createCsv();
    const blob = new Blob([csv], { type: 'text/csv' });
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.style.display = 'none';
    a.href = url;
    a.download = 'tesla_inspections.csv';
    a.click();
    a.remove();
    loading.onSuccess();
  }, [loading, inspections, createCsv]);

  return { exportToCsv, loading };
}

import { useTranslation } from 'react-i18next';
import { Card, Tabs } from '@mantine/core';
import { IconFileTypeCsv, IconLink } from '@tabler/icons-react';
import styles from './InspectionLinkCreateView.module.css';
import { InspectionLinkSingleCreate } from '../InspectionLinkSingleCreate';
import { InspectionLinkBulkCreate } from '../InspectionLinkBulkCreate';

enum LinkCreateTab {
  SINGLE = 'single',
  BULK = 'bulk',
}

export function InspectionLinkCreateView() {
  const { t } = useTranslation();

  return (
    <div className={styles['container']}>
      <div className={styles['pageTitle']}>{t('inspectionLinkCreate.pageTitle')}</div>
      <Tabs color='dark' defaultValue={LinkCreateTab.SINGLE}>
        <Card shadow='sm' padding='lg' radius='md' withBorder className={styles['card']}>
          <Card.Section>
            <Tabs.List grow>
              <Tabs.Tab value={LinkCreateTab.SINGLE} icon={<IconLink />}>
                {t('inspectionLinkCreate.single.tab')}
              </Tabs.Tab>
              <Tabs.Tab value={LinkCreateTab.BULK} icon={<IconFileTypeCsv />}>
                {t('inspectionLinkCreate.bulk.tab')}
              </Tabs.Tab>
            </Tabs.List>
          </Card.Section>
          <div className={styles['cardContent']}>
            <Tabs.Panel value={LinkCreateTab.SINGLE} className={styles['tabPanel']}>
              <div className={styles['tabPanelContent']}>
                <InspectionLinkSingleCreate />
              </div>
            </Tabs.Panel>
            <Tabs.Panel value={LinkCreateTab.BULK} className={styles['tabPanel']}>
              <div className={styles['tabPanelContent']}>
                <InspectionLinkBulkCreate />
              </div>
            </Tabs.Panel>
          </div>
        </Card>
      </Tabs>
    </div>
  );
}

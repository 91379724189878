import { Button, Overlay, useMantineTheme } from '@mantine/core';
import { useTranslation } from 'react-i18next';
import { IconDownload, IconFileTypeCsv, IconRefresh, IconUpload, IconX } from '@tabler/icons-react';
import { Dropzone, MIME_TYPES } from '@mantine/dropzone';
import { clsx } from 'clsx';
import styles from './InspectionLinkBulkCreate.module.css';
import {
  InspectionLinkBulkCreateErrorName,
  useExampleCsv,
  useInspectionLinkBulkCreate,
} from '../../hooks';

function gerErrorTranslationKey(err: unknown): string {
  if (err instanceof Error && err.name === InspectionLinkBulkCreateErrorName.CSV_PARSE_ERROR) {
    return 'inspectionLinkCreate.bulk.errors.csvFormat';
  }
  return 'inspectionLinkCreate.bulk.errors.generation';
}

export function InspectionLinkBulkCreate() {
  const theme = useMantineTheme();
  const { t } = useTranslation();
  const { downloadExampleCsv } = useExampleCsv();
  const {
    progress,
    generateLinks,
    isParseSuccessful,
    reset,
    downloadCsv,
    isDownloadAvailable,
    loading,
  } = useInspectionLinkBulkCreate();

  return (
    <div className={styles['container']}>
      {loading.isLoading && isParseSuccessful && (
        <Overlay blur={5} color='#FFF' center className={styles['overlay']}>
          <div className={styles['overlayMessage']}>{t('inspectionLinkCreate.bulk.loading')}</div>
          <div className={styles['progressBarContainer']}>
            <div className={styles['progressBarPercentage']}>{progress}%</div>
            <div className={styles['progressBar']}>
              <div className={styles['progressBarFill']} style={{ width: `${progress}%` }}></div>
            </div>
          </div>
        </Overlay>
      )}
      {loading.error && (
        <Overlay blur={5} color='#FFF' center className={styles['overlay']}>
          <div className={clsx(styles['overlayMessage'], styles['error'])}>
            {t(gerErrorTranslationKey(loading.error))}
          </div>
          <Button
            color='dark'
            variant='outline'
            leftIcon={<IconRefresh size={18} />}
            onClick={reset}
            className={styles['outlineButton']}
          >
            {t('inspectionLinkCreate.bulk.errors.tryAgain')}
          </Button>
        </Overlay>
      )}
      {isDownloadAvailable && (
        <Overlay blur={5} color='#FFF' center className={styles['overlay']}>
          <div className={styles['overlayMessage']}>
            {t('inspectionLinkCreate.bulk.success.message')}
          </div>
          <Button
            leftIcon={<IconDownload size={18} />}
            color='dark'
            onClick={downloadCsv}
            className={styles['resetButton']}
          >
            {t('inspectionLinkCreate.bulk.success.download')}
          </Button>
          <Button
            leftIcon={<IconRefresh size={18} />}
            color='dark'
            onClick={reset}
            className={clsx(styles['resetButton'], styles['outlineButton'])}
            variant='outline'
          >
            {t('inspectionLinkCreate.bulk.success.another')}
          </Button>
        </Overlay>
      )}
      <div className={styles['info']}>{t('inspectionLinkCreate.bulk.info')}</div>
      <Button
        color='dark'
        variant='outline'
        leftIcon={<IconDownload size={18} />}
        className={styles['example']}
        onClick={downloadExampleCsv}
      >
        {t('inspectionLinkCreate.bulk.downloadExample')}
      </Button>
      <Dropzone
        onDrop={generateLinks}
        accept={[MIME_TYPES.csv]}
        multiple={false}
        loading={loading.isLoading && !isParseSuccessful}
      >
        <div className={styles['dropzoneContent']}>
          <Dropzone.Accept>
            <IconUpload size={75} stroke={1.5} color={theme.colors[theme.primaryColor][6]} />
          </Dropzone.Accept>
          <Dropzone.Reject>
            <IconX size={75} stroke={1.5} color={theme.colors.red[6]} />
          </Dropzone.Reject>
          <Dropzone.Idle>
            <IconFileTypeCsv size={75} stroke={1.5} />
          </Dropzone.Idle>
          <div className={styles['dropzoneText']}>
            <div>{t('inspectionLinkCreate.bulk.dropzoneTitle')}</div>
            <div className={styles['dropzoneDescription']}>
              {t('inspectionLinkCreate.bulk.dropzoneDescription')}
            </div>
          </div>
        </div>
      </Dropzone>
    </div>
  );
}

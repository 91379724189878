import { LoadingState, useAsyncEffect, useLoadingState, useObjectMemo } from '@monkvision/common';
import { useState } from 'react';
import { useMonitoring } from '@monkvision/monitoring';
import { TeslaInspectionStatus } from '../useTeslaInspectionList/types';

export interface UseTeslaInspectionCountByStatusResult {
  counts: Record<TeslaInspectionStatus | 'all', number> | null;
  loading: LoadingState;
}

export function useTeslaInspectionCountByStatus(): UseTeslaInspectionCountByStatusResult {
  const [counts, setCounts] = useState<Record<TeslaInspectionStatus | 'all', number> | null>(null);
  const loading = useLoadingState();
  const { handleError } = useMonitoring();

  useAsyncEffect(
    () => {
      loading.start();
      // TODO : Replace this with an actual API call
      return new Promise((resolve) => {
        setTimeout(resolve, 500);
      });
    },
    [],
    {
      onResolve: () => {
        setCounts({
          all: 254,
          [TeslaInspectionStatus.INSPECTION_LINK_SEND]: 121,
          [TeslaInspectionStatus.ONGOING_CAPTURE]: 32,
          [TeslaInspectionStatus.READY_FOR_REVIEW]: 54,
          [TeslaInspectionStatus.PDF_SENT]: 23,
          [TeslaInspectionStatus.FINALIZED]: 24,
        });
        loading.onSuccess();
      },
      onReject: (err) => {
        handleError(err);
        loading.onError(err);
      },
    },
  );

  return useObjectMemo({ counts, loading });
}

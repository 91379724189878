import { MemoryRouter, Navigate, Route, Routes } from 'react-router-dom';
import { AuthGuard } from '@monkvision/common-ui-web';
import { DashboardPage, LoginPage, Page, PdfGeneratorPage } from '../pages';
import { App } from './App';

export function AppRouter() {
  return (
    <MemoryRouter>
      <Routes>
        <Route path='/' element={<App />}>
          <Route path='/' element={<Navigate to={Page.DASHBOARD} />} />
          <Route path={Page.LOG_IN} element={<LoginPage />} />
          <Route
            path={Page.DASHBOARD}
            element={
              <AuthGuard redirectTo={Page.LOG_IN}>
                <DashboardPage />
              </AuthGuard>
            }
            index
          />
          <Route
            path={Page.PDF_GENERATOR}
            element={
              <AuthGuard redirectTo={Page.LOG_IN}>
                <PdfGeneratorPage />
              </AuthGuard>
            }
            index
          />
          <Route path='*' element={<Navigate to={Page.DASHBOARD} />} />
        </Route>
      </Routes>
    </MemoryRouter>
  );
}

import { DamageType, MileageUnit, VehiclePart } from '@monkvision/types';

export enum TeslaModel {
  MODEL_S = 'Model S',
  MODEL_3 = 'Model 3',
  MODEL_X = 'Model X',
  MODEL_Y = 'Model Y',
}

export enum TeslaCountry {
  UK = 'UK',
  NL = 'NL',
  FR = 'FR',
  US = 'US',
  DE = 'DE',
}

export enum TeslaInspectionStatus {
  INSPECTION_LINK_SEND = 'inspection_link_send',
  ONGOING_CAPTURE = 'ongoing_capture',
  READY_FOR_REVIEW = 'ready_for_review',
  PDF_SENT = 'pdf_sent',
  FINALIZED = 'finalized',
}

export interface TeslaDeductionDetails {
  part: VehiclePart;
  pricing: number;
  damages: DamageType[];
}

export interface TeslaInspection {
  status: TeslaInspectionStatus;
  vin?: string;
  make?: string;
  model?: TeslaModel;
  lastUpdated?: Date;
  leaseMaturityDate?: Date;
  country: TeslaCountry;
  licencePlate?: string;
  inspectionId: string;
  odometer?: number;
  totalPricing?: number;
  deductionDetails?: TeslaDeductionDetails[];
  originalDeductionDetails?: TeslaDeductionDetails[];
  pdfUrl?: string;
  inspectionStart?: Date;
  inspectionEnd?: Date;
  mileageUnit?: MileageUnit;
}

export interface Pagination {
  rowsPerPage: number;
  start: number;
  end: number;
  totalItems: number;
}

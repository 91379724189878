import { Text, View } from '@react-pdf/renderer';
import { useTranslation } from 'react-i18next';
import {
  damageTypeLabels,
  flatMap,
  useObjectTranslation,
  vehiclePartLabels,
} from '@monkvision/common';
import { styles } from './Summary.styles';
import { SectionTitle } from '../SectionTitle';
import { PdfInteriorDamage, PdfVehicleSideData } from '../../../hooks/useTeslaPdfGenerator/types';
import { formatPrice } from '../../../utils';

export interface SummaryProps {
  damagedSides: PdfVehicleSideData[];
  interiorDamages: PdfInteriorDamage[];
  currency: string;
  lang: string;
}

export function Summary({ damagedSides, interiorDamages, currency, lang }: SummaryProps) {
  const { t } = useTranslation('translation', { lng: lang });
  const { tObj } = useObjectTranslation('translation', { lng: lang });

  const parts = flatMap(damagedSides, ({ damagedParts }) => damagedParts).sort(
    (a, b) => b.repairCost - a.repairCost,
  );
  const totalPrice =
    parts.reduce((prev, { repairCost }) => prev + repairCost, 0) +
    interiorDamages.reduce((prev, { deduction }) => prev + deduction, 0);

  return (
    <View style={styles.container}>
      <SectionTitle title={t('pdf.summary.title')} />
      <Text style={styles.tableTitle}>{t('pdf.summary.repairPrice.title')}</Text>
      <View style={{ ...styles.row, ...styles.headerRow }}>
        <View style={styles.cell}>
          <Text>{t('pdf.summary.repairPrice.vehiclePart')}</Text>
        </View>
        <View style={styles.cell}>
          <Text>{t('pdf.summary.repairPrice.damageType')}</Text>
        </View>
        <View style={{ ...styles.cell, ...styles.priceCell }}>
          <Text>{t('pdf.summary.repairPrice.price')}</Text>
        </View>
      </View>
      <Text style={{ ...styles.tableTitle, ...styles.innerTableTitle }}>
        {t('pdf.summary.repairPrice.exterior')}
      </Text>
      {parts.map(({ name, damages, repairCost }) => (
        <View key={name} style={styles.row}>
          <View style={styles.cell}>
            <Text>{vehiclePartLabels[name] ? tObj(vehiclePartLabels[name]) : 'unknown'}</Text>
          </View>
          <View style={styles.cell}>
            <Text>
              {damages
                .map((type) => (damageTypeLabels[type] ? tObj(damageTypeLabels[type]) : 'unknown'))
                .join(', ')}
            </Text>
          </View>
          <View style={{ ...styles.cell, ...styles.priceCell }}>
            <Text>{formatPrice(repairCost, currency, lang)}</Text>
          </View>
        </View>
      ))}
      <Text style={{ ...styles.tableTitle, ...styles.innerTableTitle }}>
        {t('pdf.summary.repairPrice.interior')}
      </Text>
      {interiorDamages.map(({ area, damageTypes, deduction }) => (
        <View key={area} style={styles.row}>
          <View style={styles.cell}>
            <Text>{area}</Text>
          </View>
          <View style={styles.cell}>
            <Text>{damageTypes}</Text>
          </View>
          <View style={{ ...styles.cell, ...styles.priceCell }}>
            <Text>{formatPrice(deduction, currency, lang)}</Text>
          </View>
        </View>
      ))}
      <View style={styles.totalRow}>
        <Text>{t('pdf.summary.repairPrice.totalRepairPrice')}</Text>
        <Text style={styles.totalPrice}>{formatPrice(totalPrice, currency, lang)}</Text>
      </View>
    </View>
  );
}

import { CurrencyCode } from '@monkvision/types';
import { useTranslation } from 'react-i18next';
import { DoneButton } from '../DoneButton';
import { InteriorDamage, useInteriorModal } from './hooks';
import { styles } from './InteriorManipulatorModal.styles';

export interface InteriorManipulatorProps {
  interiorDamage?: InteriorDamage;
  currency?: CurrencyCode;
  onConfirm?: (interiorDamage: InteriorDamage) => void;
  onCancel?: () => void;
}

export function InteriorManipulatorModal({
  interiorDamage,
  currency = CurrencyCode.USD,
  onConfirm = () => {},
  onCancel = () => {},
}: InteriorManipulatorProps) {
  const { t } = useTranslation();
  const {
    newInteriorDamage,
    handleAreaChange,
    handleInteriorDamageTypeChange,
    handleInteriorDeductionChange,
  } = useInteriorModal({
    interiorDamage,
  });

  if (!newInteriorDamage) {
    return null;
  }

  return (
    <div style={styles['container']}>
      <div style={styles['inputSectionContainer']}>
        <div style={styles['section']}>{t('inspectionReview.damageManipulator.area')}</div>
        <div style={styles['inputSection']}>
          <input
            type='text'
            style={styles['price']}
            value={newInteriorDamage.area}
            placeholder='Input Area'
            onChange={(e) => {
              const { value } = e.target;
              handleAreaChange(value);
            }}
          />
        </div>
      </div>
      <div style={styles['inputSectionContainer']}>
        <div style={styles['section']}>{t('inspectionReview.damageManipulator.damageTypes')}</div>
        <div style={styles['inputSection']}>
          <input
            type='text'
            style={styles['price']}
            value={newInteriorDamage.damage_type}
            placeholder='Input Damage types'
            onChange={(e) => {
              const { value } = e.target;
              handleInteriorDamageTypeChange(value);
            }}
          />
        </div>
        <div style={styles['inputSectionContainer']}>
          <div style={styles['section']}>{t('inspectionReview.damageManipulator.deduction')}</div>
          <div style={styles['inputSection']}>
            {currency === CurrencyCode.USD && <div style={styles['currency']}>$</div>}
            <input
              type='text'
              style={styles['price']}
              maxLength={4}
              value={newInteriorDamage?.repair_cost ?? ''}
              onChange={(e) => {
                const { value } = e.target;
                if (value === '' || /^\d*$/.test(value)) {
                  handleInteriorDeductionChange(value === '' ? null : Number(value));
                }
              }}
            />
            {currency === CurrencyCode.EUR && <div>€</div>}
          </div>
        </div>
      </div>
      <div style={styles['footerContainer']}>
        <button style={{ ...styles['button'], ...styles['cancel'] }} onClick={onCancel}>
          CANCEL
        </button>
        <DoneButton onConfirm={() => onConfirm(newInteriorDamage)}>
          {t('inspectionReview.damageManipulator.doneBtn').toUpperCase()}
        </DoneButton>
      </div>
    </div>
  );
}

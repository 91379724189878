import { useEffect, useMemo, useState } from 'react';
import { LoadingState, useAsyncEffect, useLoadingState, useObjectMemo } from '@monkvision/common';
import { useMonitoring } from '@monkvision/monitoring';
import { Pagination, TeslaInspection } from './types';
import { FAKE_INSPECTIONS, FAKE_TOTAL_ELEMENTS } from './fake';
import { TeslaInspectionListFilters } from '../useTeslaInspectionListFilters/types';

export const INITIAL_ROWS_PER_PAGE = 25;

export interface UseTeslaInspectionListResult {
  loading: LoadingState;
  inspections: TeslaInspection[];
  pagination: Pagination | null;
  setRowsPerPage: (value: number) => void;
  goToNextPage: () => void;
  goToPreviousPage: () => void;
}

export function useTeslaInspectionList({
  sortDirection,
  sortBy,
  statuses,
  leaseMaturityDate,
  lastUpdated,
  searchInput,
}: TeslaInspectionListFilters): UseTeslaInspectionListResult {
  const [limit, setLimit] = useState(INITIAL_ROWS_PER_PAGE);
  const [before, setBefore] = useState<string | null>(null);
  const [previous, setPrevious] = useState<string | null>(null);
  const [next, setNext] = useState<string | null>(null);
  const [totalItems, setTotalItems] = useState<number | null>(null);
  const [page, setPage] = useState(0);
  const [previousPage, setPreviousPage] = useState(0);
  const [inspections, setInspections] = useState<TeslaInspection[]>([]);
  const loading = useLoadingState();
  const { handleError } = useMonitoring();

  const pagination = useMemo(
    () => ({
      rowsPerPage: limit,
      start: page * limit + 1,
      end: Math.min((page + 1) * limit, totalItems ?? Infinity),
      totalItems: totalItems ?? 0,
    }),
    [limit, page, totalItems],
  );

  useEffect(() => {
    setBefore(null);
    setPrevious(null);
    setNext(null);
    setPage(0);
  }, [sortDirection, sortBy, statuses, leaseMaturityDate, lastUpdated, searchInput]);

  const setRowsPerPage = (value: number) => {
    setLimit(value);
    setBefore(null);
    setPrevious(null);
    setNext(null);
    setPage(0);
  };

  const goToNextPage = () => {
    if (totalItems && limit * (page + 1) < totalItems) {
      setPreviousPage(page);
      setPage(page + 1);
      setBefore(next);
    }
  };

  const goToPreviousPage = () => {
    if (page > 0) {
      setPreviousPage(page);
      setPage(page - 1);
      setBefore(previous);
    }
  };

  useAsyncEffect(
    () => {
      loading.start();
      // TODO : Make a real API Call
      return new Promise((resolve) => {
        setTimeout(resolve, 500);
      });
    },
    [
      sortDirection,
      sortBy,
      statuses,
      leaseMaturityDate,
      lastUpdated,
      searchInput,
      limit,
      before,
      previousPage,
    ],
    {
      onResolve: () => {
        loading.onSuccess();

        // TODO : Extract Pagination Data
        const items = FAKE_INSPECTIONS;
        const total = FAKE_TOTAL_ELEMENTS;
        const previousId = `${Date.now()}-previous`;
        const nextId = `${Date.now()}-next`;

        setInspections(items);
        setNext(nextId);
        setPrevious(previousId);
        setTotalItems(total);
      },
      onReject: (err) => {
        loading.onError(err);
        handleError(err);
        setPage(previousPage);
      },
    },
  );

  return useObjectMemo({
    loading,
    inspections,
    pagination,
    setRowsPerPage,
    goToNextPage,
    goToPreviousPage,
  });
}

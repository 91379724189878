import { TeslaInspectionStatus } from '../useTeslaInspectionList/types';

export enum SortDirection {
  ASCENDING = 'ascending',
  DESCENDING = 'descending',
}

export enum SortByProperty {
  LAST_UPDATED = 'lastUpdated',
  LEASE_MATURITY_DATE = 'leaseMaturityDate',
}

export interface DateFilter {
  min: Date;
  max: Date;
}

export interface TeslaInspectionListFilters {
  sortDirection: SortDirection;
  sortBy: SortByProperty;
  statuses: TeslaInspectionStatus[] | null;
  leaseMaturityDate: DateFilter | null;
  lastUpdated: DateFilter | null;
  searchInput: string | null;
}

import { Button } from '@monkvision/common-ui-web';
import { ImageDetailedViewProps, useImageDetailedView, useImageDetailedViewStyles } from './hooks';
import { ImageDetailedViewOverlay } from './ImageDetailedViewOverlay';

/**
 * This component is used to display the preview of an inspection image, as well as additional data such as its label
 * etc. If this component is used mid-capture, set the `captureMode` prop to `true` so that you'll enable features such
 * as compliance errors, retakes etc.
 */
export function ImageDetailedView(props: ImageDetailedViewProps) {
  const { backgroundImage, scale, origin, isMouseOver } = useImageDetailedView(props);
  const {
    mainContainerStyle,
    overlayStyle,
    topContainerStyle,
    imageContainerStyle,
    leftContainerStyle,
    overlayContainerStyle,
    rightContainerStyle,
    closeButton,
    galleryButton,
    prevButton,
    nextButton,
  } = useImageDetailedViewStyles(props, isMouseOver);

  return (
    <div className='parent' style={mainContainerStyle}>
      <img
        style={{
          ...imageContainerStyle,
          transform: `scale(${scale})`,
          transformOrigin: `${origin.x} ${origin.y}`,
        }}
        src={backgroundImage}
        alt={backgroundImage}
      />
      <div style={overlayStyle}>
        <div style={topContainerStyle}>
          <div style={leftContainerStyle}>
            <Button
              onClick={props.onClose}
              icon='close'
              size='small'
              primaryColor={closeButton.primaryColor}
              secondaryColor={closeButton.secondaryColor}
            />
          </div>
          <div style={rightContainerStyle}>
            <Button
              disabled={!(props.showGalleryButton ?? true)}
              onClick={props.onShowDamage}
              icon={props.showDamage ? 'visibility-off' : 'visibility-on'}
              primaryColor={galleryButton.primaryColor}
              secondaryColor={galleryButton.secondaryColor}
              style={galleryButton.style}
            >
              {props.showDamage ? 'Hide Damage' : 'Show Damage'}
            </Button>
          </div>
        </div>
        <div style={overlayContainerStyle}>
          <Button
            style={prevButton}
            onClick={props.onPrev}
            icon='chevron-left'
            primaryColor={closeButton.primaryColor}
            secondaryColor={closeButton.secondaryColor}
          />
          <ImageDetailedViewOverlay
            image={props.image}
            captureMode={props.captureMode}
            reportMode={!!props.reportMode}
            onRetake={props.captureMode ? props.onRetake : undefined}
          />
          <Button
            style={nextButton}
            onClick={props.onNext}
            icon='chevron-right'
            primaryColor={closeButton.primaryColor}
            secondaryColor={closeButton.secondaryColor}
          />
        </div>
      </div>
    </div>
  );
}

import { useTranslation } from 'react-i18next';
import { SwitchButton } from '@monkvision/common-ui-web';
import { useMonkTheme } from '@monkvision/common';
import { styles } from './DamageSwitchButton.styles';

export interface DamageSwitchButtonProps {
  hasDamage: boolean;
  onSwitch?: () => void;
}

export function DamagesSwitchButton({ hasDamage, onSwitch }: DamageSwitchButtonProps) {
  const { t } = useTranslation();
  const { palette } = useMonkTheme();

  const translation = t(`inspectionReview.damagesSwitchBtn.damaged`);
  return (
    <div style={styles['container']}>
      <div>
        <div style={styles['subtitle']}>{translation}</div>
      </div>
      <SwitchButton
        checked={hasDamage}
        checkedPrimaryColor={palette.primary.base}
        checkedSecondaryColor={palette.text.white}
        uncheckedPrimaryColor={palette.secondary.base}
        uncheckedSecondaryColor={palette.background.light}
        onSwitch={onSwitch}
      />
    </div>
  );
}
